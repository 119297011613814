@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: Industry, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: var(--black);
  color: var(--neutral);
  font-weight: 300;
}

html {
  height: 100vh;

  /* Mobile sizing */
  font-size: 12px;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--white);
}

body > div:first-of-type {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #010101;
  --neutral: #e2e2e2;
  --primary-1: #f01919;
  --primary-2: #05bed6;
  --secondary-1: #ff8e06;
}

.title {
  font-family: GALACTIC VANGUARDIAN NCV;
}

.subtitle {
  font-family: "ChubbyChoo";
}

/* Special classes */
.chubby-choo-vertical-fix {
  position: relative;
  /* top: -2px; */
}
.hover-white:hover{
  color: white !important;
}
.fwbold{
  font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: bold;
}
@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
      0 0 1px #fff,
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px rgb(224, 244, 1),
      0 0 8px rgb(225, 255, 0),
      0 0 12px rgb(234, 255, 0),
      0 0 14px rgb(255, 200, 0),
      0 0 16px #ff8e06;
  }
  20%, 24%, 55% {       
    text-shadow: none;
  }
}
@keyframes flashy {
 
  from {
    color: white;
  }
  to {
    color: #ff8e06;
  }
 
}

/* .neonText {
  animation: flashy 1s infinite  !important;
  color: white;
} */

@keyframes blink-animation {
  from {
    color: #ff8e06 ;
  }
  to {
    color: white ;
  }
}
@-webkit-keyframes blink-animation {
  from {
    color: #ff8e06 ;
  }
  to {
    color: white ;
  }
}
.textwhite{
  color: white!important;
}
.textyellow{
  color: #ff8e06 !important;
}
/* .neonText{
  color: #ff8e06 ;
          animation: blink-animation 1s steps(2, end) infinite !important;
          -webkit-animation: blink-animation 1s steps(2, end) infinite !important;
        } */


.allGameTitle{
  color: #05bed6;
  font-weight: bold;
  font-size: 20px;
}

.allGameDetails{
    margin-bottom: 0.5em;
}

.allGameDesc,.allGamePrize{
    text-align: left;
}

.allGamePrize span{
    color: #05bed6;
}
        .allGameItem{
        
         
          border: 3px solid #05bed6 !important;
          text-align: "center" !important;
          line-height: 240px !important;
          box-sizing: border-box !important;
          margin: 10px !important
        }
        .sliderItem{
        
          width: 160px !important;
          border: 3px solid #05bed6 !important;
          /* text-align: "center" !important; */
          /* line-height: 240px !important; */
          box-sizing: border-box !important;
          margin: 10px !important
        }
        div.sliderItem{
          height: 160px;
        }
        .carouselContainer > div > div {
          max-width: 550px  !important;
        }
        .carouselContainer > div  {
          width: 64%;
          justify-content: space-between;
          user-select: text;
          margin: auto;
        }
        .moreGames{
          color: #05bed6 !important;
          font-weight: bold;
          /*text-align: start;*/
          max-width: 50%;
          margin: auto;
        }
        .text-theme{
          color:#05bed6;
        }
        .fw-bold{
          font-weight: bold;
        }
        .ms-auto{
          margin-left: auto;
        }
        .d-flex{
          display: flex;
        }
        
        
        @media only screen and     (max-width:992px) {
          .carouselContainer > div  {
            width: 100%;
            justify-content: space-between;
            user-select: text;
            margin: auto;
          }

        }

        @media only screen and     (max-width:768px) {
          .carouselContainer > div > div {
            max-width: 365px  !important;
          }
          .carouselInner{
            width: 100%;
            justify-content: space-between;
            user-select: text;
            margin: auto;
          }
          .fwbold{
            font-size: 1.125rem;
              line-height: 1.75rem;
              font-weight: normal;
          }
          .d-sm-none{
            display: none;
          }
        }


@media screen(sm) {
  html {
    /* Normal font size  */
    font-size: 16px;
  }

  .chubby-choo-vertical-fix {
    top: -5px;
  }
}



/* Bootstrap Classes */
/* Bootstrap Classes */
/* Bootstrap Classes */

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.search{
  color: black !important;
  padding: 5px;
  width: 250px;
}
.justify-content-between{
  justify-content: space-between;
}
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}



